import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import logo from '../assets/fanup_logo_white.png';
import LoadingSpinner from './LoadingSpinner';
import styles from './UserSignupPage.module.css';
import uploadIcon from '../assets/upload.svg';
import CustomNavbar from './CustomNavbar';

const MySwal = withReactContent(Swal);

function UserSignupPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPlayer, setIsPlayer] = useState(true);
  const [screenshotFile, setScreenshotFile] = useState(null);
  const [teamLogoFile, setTeamLogoFile] = useState(null);
  const [error, setError] = useState('');
  const [tournaments, setTournaments] = useState([]);
  const [registeredTournaments, setRegisteredTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(location.state?.tournamentName || '');
  const [collegeName, setCollegeName] = useState(location.state?.collegeName || sessionStorage.getItem('collegeName') || 'undefined');
  const [studentIdNumber, setStudentIdNumber] = useState(''); // Initialize state for Student ID Number
  const [coordinatorName, setCoordinatorName] = useState(location.state?.coordinatorName || sessionStorage.getItem('userFullName') || 'undefined');
  const [coordinatorEmail, setCoordinatorEmail] = useState(sessionStorage.getItem('userEmail') || '');
  const [coordinatorNumber, setCoordinatorNumber] = useState(location.state?.coordinatorNumber || sessionStorage.getItem('userPhoneNumber') || 'undefined');
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      MySwal.fire({
        title: 'Authentication Required',
        text: 'You must log in with Google before signing up for a tournament.',
        icon: 'warning',
        confirmButtonText: 'OK',
        color:"#fff",
        background:"#333",
        theme:"dark",
        backdrop: true,
      }).then(() => {
        navigate('/');
      });
      return;
    }

    if (collegeName === "undefined" || coordinatorName === "undefined" || coordinatorNumber === "undefined") {
      const fetchUserDetails = async () => {
        try {
          const response = await fetch(`${BACKEND_URL}/fetch-college-name/college-name`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setCollegeName(data.collegeName);
            setCoordinatorName(data.fullName);
            setCoordinatorNumber(data.phoneNumber);
            sessionStorage.setItem('collegeName', data.collegeName);
            sessionStorage.setItem('userFullName', data.fullName);
            sessionStorage.setItem('userPhoneNumber', data.phoneNumber);
          } else {
            MySwal.fire({
              title: 'Information Not Found',
              text: 'We could not retrieve your details. Please log in.',
              icon: 'error',
              color:"#fff",
              background:"#333",
              confirmButtonText: 'OK',
              theme:"dark-theme",
              customClass:"popup",
              backdrop: true,
            }).then(() => {
              navigate('/');
            });
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
          setError('An error occurred while fetching user details.');
        }
      };

      fetchUserDetails();
    }
  }, [collegeName, coordinatorName, coordinatorNumber, BACKEND_URL, navigate]);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const [tournamentResponse, registeredResponse] = await Promise.all([
          fetch(`${BACKEND_URL}/tournament/all`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }),
          fetch(`${BACKEND_URL}/registered-tournaments`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
        ]);

        const tournamentsData = await tournamentResponse.json();
        const registeredTournamentsData = await registeredResponse.json();

        if (tournamentResponse.ok && registeredResponse.ok) {
          setTournaments(
            tournamentsData.filter(
              (tournament) => !registeredTournamentsData.includes(tournament.tournamentName)
            )
          );
          setRegisteredTournaments(registeredTournamentsData);
        } else {
          setError('Failed to fetch tournaments or registrations');
        }
      } catch (error) {
        console.error('Error fetching tournaments:', error);
        setError('An error occurred while fetching tournaments.');
      }
    };

    fetchTournaments();
  }, [BACKEND_URL]);

  const toggleRole = (role) => {
    setIsPlayer(role === 'player');
    setTeamLogoFile(null); // Clear team logo file if switching to player
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file && file.size <= 10 * 1024 * 1024) { // 10 MB limit
      if (type === 'screenshot') {
        setScreenshotFile(file);
      } else if (type === 'teamLogo') {
        setTeamLogoFile(file);
      }
      setError('');
    } else {
      setError('File size exceeds 10 MB limit');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    setLoading(true); // Start loading spinner

    if (!screenshotFile) {
      setError('Please upload a payment screenshot.');
      setLoading(false);
      setIsSubmitting(false); // Allow resubmission
      return;
    }

    if (!selectedTournament) {
      setError('Please select a tournament.');
      setLoading(false);
      setIsSubmitting(false); // Allow resubmission
      return;
    }

    const formData = new FormData();
    formData.append('role', isPlayer ? 'player' : 'owner');
    formData.append('screenshot', screenshotFile);
    formData.append('tournamentName', selectedTournament);

    if (isPlayer) {
      if (selectedTournament === "J-Slam") {
        // Append fields specific to "J-Slam"
        formData.append('playerName', e.target.playerName.value);
        formData.append('studentIdNumber', e.target.studentIdNumber.value);
        formData.append('position', e.target.position.value);
        formData.append('playerDescription', e.target.playerDescription.value);
        formData.append('collegeName', e.target.collegeName.value); // Add College Name input
      } else {
        // Append standard fields for other tournaments
        formData.append('position', e.target.position.value);
        formData.append('batch', e.target.batch.value);
        formData.append('injury', e.target.injury.value);
        formData.append('collegeName', collegeName); // Use collegeName for other tournaments
      }
    }

    if (!isPlayer) {
      formData.append('teamName', e.target.teamName.value);
      formData.append('batch', e.target.batch.value);
      formData.append('teamLogo', teamLogoFile);
      formData.append('collegeName', collegeName); // Use collegeName for other tournaments
    }

    formData.append('email', coordinatorEmail || '');
    formData.append('fullName', coordinatorName || '');
    formData.append('phoneNumber', coordinatorNumber || '');

    const token = localStorage.getItem('authToken');
    const endpoint = isPlayer ? 'player-signup' : 'team-signup';

    try {
      const response = await fetch(`${BACKEND_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (response.ok) {
        MySwal.fire({
          title: "Success",
          text: "You have registered successfully!",
          icon: "success",
          color:"#fff",
          background:"#333",
        });
        navigate(`/tournament/${selectedTournament}`);
      } else {
        setError(result.error);
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false); // Stop loading spinner
      setIsSubmitting(false); // Allow resubmission
    }
  };

  return (
    <div className={styles.signupPage}>
      {loading && <LoadingSpinner />} {/* Render the spinner if loading */}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>Sign Up Page</span>
        </div>
      </nav>
      <CustomNavbar /> {/* Use the navbar component here */}
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.signupText}>Sign Up</h2>
          <div className={styles.roleToggle}>
            <button
              className={`${styles.toggleButton} ${isPlayer ? styles.active : ''}`}
              onClick={() => toggleRole('player')}
            >
              Player
            </button>
            <button
              className={`${styles.toggleButton} ${!isPlayer ? styles.active : ''}`}
              onClick={() => toggleRole('owner')}
            >
              Owner
            </button>
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="tournamentName">Select Tournament:</label>
              <select
                id="tournamentName"
                className={styles.inputField}
                value={selectedTournament}
                onChange={(e) => setSelectedTournament(e.target.value)}
                required
              >
                <option value="">Select Tournament</option>
                {tournaments.map((tournament) => (
                  <option key={tournament._id} value={tournament.tournamentName}>
                    {tournament.tournamentName}
                  </option>
                ))}
              </select>
            </div>
            {isPlayer ? (
              <>
                {/* Conditionally render inputs based on selectedTournament */}
                {selectedTournament === "J-Slam" ? (
                  <>
                    <input
                      type="text"
                      name="playerName"
                      placeholder="Player Name as per Student ID"
                      className={styles.inputField}
                      required
                    />
                    <input
                      type="text"
                      name="studentIdNumber"
                      placeholder="Student ID Number"
                      value={studentIdNumber} // Manage student ID number separately
                      onChange={(e) => setStudentIdNumber(e.target.value)}
                      className={styles.inputField}
                      required
                    />
                    <input
                      type="text"
                      name="position"
                      placeholder="Position"
                      className={styles.inputField}
                      required
                    />
                    <input
                      type="text"
                      name="playerDescription"
                      placeholder="Player Description"
                      className={styles.inputField}
                      required
                    />
                    <input
                      type="text"
                      name="collegeName"
                      placeholder="College Name"
                      value={collegeName}
                      onChange={(e) => setCollegeName(e.target.value)} // Allow editing
                      className={styles.inputField}
                      required
                    />
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      name="position"
                      placeholder="Position"
                      className={styles.inputField}
                      required
                    />
                    <input
                      type="text"
                      name="university"
                      placeholder="University"
                      value={collegeName}
                      readOnly
                      className={styles.inputField}
                    />
                    <input
                      type="text"
                      name="batch"
                      placeholder="Batch"
                      className={styles.inputField}
                      required
                    />
                    <input
                      type="text"
                      name="injury"
                      placeholder="Injury"
                      className={styles.inputField}
                      required
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <input
                  type="text"
                  name="teamName"
                  placeholder="Team Name"
                  className={styles.inputField}
                  required
                />
                <input
                  type="text"
                  name="university"
                  placeholder="University"
                  value={collegeName}
                  readOnly
                  className={styles.inputField}
                />
                <input
                  type="text"
                  name="batch"
                  placeholder="Batch"
                  className={styles.inputField}
                  required
                />
                <div className={styles.uploadField}>
                  <span>Team Logo</span>
                  <label htmlFor="teamLogo" className={styles.fileInput}>
                    <img src={uploadIcon} alt="Upload" />
                    {teamLogoFile && <span className={styles.fileName}>{teamLogoFile.name}</span>}
                  </label>
                  <input
                    type="file"
                    id="teamLogo"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, 'teamLogo')}
                    style={{ display: 'none' }} // Hide the default input
                  />
                </div>
              </>
            )}
            <div className={styles.uploadField}>
              <span className={styles.screenshotText}>Payment Screenshot</span>
              <label htmlFor="paymentScreenshot" className={styles.fileInput}>
                <img src={uploadIcon} alt="Upload" />
                {screenshotFile && <span className={styles.fileName}>{screenshotFile.name}</span>}
              </label>
              <input
                type="file"
                id="paymentScreenshot"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'screenshot')}
                style={{ display: 'none' }} // Hide the default input
              />
            </div>
            {error && <p className={styles.errorText}>{error}</p>}
            <button type="submit" className={styles.submitButton} disabled={isSubmitting}>Submit</button> {/* Disable button if submitting */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserSignupPage;
