import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/fanup_logo_white.png';
import LoadingSpinner from './LoadingSpinner'; // Import the spinner
import styles from './LoginPage.module.css';
import CustomNavbar from './CustomNavbar';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID; 

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: handleGoogleCallbackResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById('GoogleButton'),
        { theme: 'outline', size: 'large', shape: 'circle', type: 'standard' }
      );
    };

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);
  }, []);

  const handleGoogleCallbackResponse = async (response) => {
    setLoading(true); // Start loading spinner
    try {
      const res = await fetch(`${BACKEND_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: response.credential }),
        credentials: 'include' // Ensure cookies are sent with the request
      });
  
      if (res.ok) {
        const data = await res.json();
        localStorage.setItem('authToken', data.token);
        if (data.email) {
          sessionStorage.setItem('userEmail', data.email);
          sessionStorage.setItem('userFullName', data.fullName);
          sessionStorage.setItem('userPhoneNumber', data.phoneNumber);
          sessionStorage.setItem('collegeName', data.collegeName);
          checkPlayerRegistration(data.email);
        }
      } else {
        console.error('Google login failed');
      }
    } catch (error) {
      console.error('Error during Google login:', error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const checkPlayerRegistration = async (email) => {
    setLoading(true); // Start loading spinner
    try {
      const res = await fetch(`${BACKEND_URL}/check-player/${email}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
  
      if (res.ok) {
        const player = await res.json();
        if (player && player.tournamentName) {
          sessionStorage.setItem('collegeName', player.university || '');
          sessionStorage.setItem('userFullName', player.fullName || '');
          sessionStorage.setItem('userPhoneNumber', player.phoneNumber || '');
          navigate(`/tournament/${player.tournamentName}`);
        } else {
          navigate('/signup');
        }
      } else {
        navigate('/signup');
      }
    } catch (error) {
      console.error('Error checking player registration:', error);
      navigate('/signup');
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleRegularLogin = async () => {
    setLoading(true); // Start loading spinner
    try {
      const res = await fetch(`${BACKEND_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include' // Ensure cookies are sent with the request
      });
  
      if (res.ok) {
        const data = await res.json();
        localStorage.setItem('authToken', data.token);
        sessionStorage.setItem('userEmail', email);
        sessionStorage.setItem('userFullName', data.fullName);
        sessionStorage.setItem('userPhoneNumber', data.phoneNumber);
        sessionStorage.setItem('collegeName', data.collegeName);
        checkPlayerRegistration(email);
      } else {
        console.error('Regular login failed');
      }
    } catch (error) {
      console.error('Error during regular login:', error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <div className={styles.loginPage}>
      {loading && <LoadingSpinner />} {/* Render the spinner if loading */}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>Registration Page</span>
        </div>
      </nav>
      <CustomNavbar /> {/* Use the navbar component here */}
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.signupText}>Login</h2>
          <input
            type="email"
            placeholder="Email"
            className={styles.inputField}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className={styles.inputField}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className={styles.loginButton} onClick={handleRegularLogin}>
            Login
          </button>
          <a href="#" className={styles.forgotPasswordLink}>
            Forgot Password?
          </a>
          <div className={styles.orSeparator}>
            <hr className={styles.line} />
            <span>OR</span>
            <hr className={styles.line} />
          </div>
          <div id="GoogleButton"></div>
          <div className={styles.signupLink}>
            Don't Have an Account?{' '}
            <a href="/signup" className={styles.signUpLinkText}>
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
