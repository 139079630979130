import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/fanup_logo_white.png';
import LoadingSpinner from './LoadingSpinner';
import CustomNavbar from './CustomNavbar';
import styles from './ViewTournamentsPage.module.css';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ViewTournamentsPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); 
  const [tournaments, setTournaments] = useState([]);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setLoading(true);
    fetch(`${BACKEND_URL}/tournament/all`)
      .then(response => response.json())
      .then(data => {
        setTournaments(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching tournaments:', error);
        setLoading(false);
      });
  }, [BACKEND_URL]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const formatTournamentDate = (startDate, endDate) => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const convertDriveLinkToImageSrc = (driveLink) => {
    const fileId = driveLink.split('/d/')[1].split('/')[0];
    return `https://drive.google.com/uc?export=view&id=${fileId}`;
  };

  const handleViewClick = (tournamentName) => {
    navigate(`/tournament/${tournamentName}`);
  };

  const handleRegisterClick = (tournamentName) => {
    navigate('/usersignup', { state: { tournamentName } });
  };

  return (
    <div className={styles.loginPage}>
      {loading && <LoadingSpinner />} {/* Render the spinner if loading */}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>Tournament Page</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.tournamentListContainer}>
        {tournaments
          .filter(tournament => tournament.verificationCode) // Filter out tournaments without verificationCode
          .map((tournament, index) => (
            <div key={index} className={styles.tournamentRow}>
              <div className={styles.tournamentLogo}>
                <img src={tournament.logo} alt={`${tournament.name} Logo`} />
              </div>
              <div className={styles.tournamentDetails}>
                <h3 className={styles.nameOfTournament}>{tournament.tournamentName}</h3>
                <p className={styles.sponsorText}>Sponsored by {tournament.sponsorList ? tournament.sponsorList : '<<TOURNAMENT SPONSOR>>'}</p>
                <p className={styles.locationText}>
                  <FontAwesomeIcon color='red' className={styles.locationPin} icon={faMapMarkerAlt} />
                  {tournament.location}
                </p>
              </div>
              <div className={styles.tournamentDate}>
                <p>{formatTournamentDate(tournament.startDate, tournament.endDate)}</p>
              </div>
              <div className={styles.tournamentActions}>
                <button 
                  className={styles.viewButton}
                  onClick={() => handleViewClick(tournament.tournamentName)}
                >
                  View
                </button>
                <button 
                  className={styles.registerButton}
                  onClick={() => handleRegisterClick(tournament.tournamentName)}
                >
                  Register
                </button>
              </div>
            </div>
        ))}
      </div>
    </div>
  );
}

export default ViewTournamentsPage;
