import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import styles from './CreateTournament.module.css';
import logo from '../assets/fanup_logo_white.png';
import CustomNavbar from './CustomNavbar';

const CreateTournament = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Attempt to get tournamentName and collegeName from sessionStorage first
  const tournamentNameFromSession = sessionStorage.getItem('tournamentName') || '';
  const collegeName = sessionStorage.getItem('collegeName') || '';
  const coordinatorEmail = sessionStorage.getItem('userEmail') || '';

  const [step, setStep] = useState(1);

  const [newTournament, setNewTournament] = useState({
    tournamentName: tournamentNameFromSession || '',
    sports: [],
    startDate: '',
    endDate: '',
    location: '',
    logo: '',
    brochure: '',
    numTeams: 0,
    numPlayers: 0,
    numCisMen: 0,
    numNonCisMen: 0,
    registrationFees: 0,
    verificationCode: '',
    rules: '',
    collegeName: collegeName || '',
    coordinatorEmail: coordinatorEmail || ''
  });

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const sportsOptions = [
    { value: 'Basketball', label: 'Basketball' },
    { value: 'Football', label: 'Football' },
    { value: 'Tennis', label: 'Tennis' },
    { value: 'Badminton', label: 'Badminton' },
    { value: 'Cricket', label: 'Cricket' },
    { value: 'Pool', label: 'Pool' },
    { value: 'Squash', label: 'Squash' },
    { value: 'Table-Tennis', label: 'Table-Tennis' },
    { value: 'Chess', label: 'Chess' },
    { value: 'Volleyball', label: 'Volleyball' },
    { value: 'Powerlifting', label: 'Powerlifting' },
    { value: 'Swimming', label: 'Swimming' }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTournament({ ...newTournament, [name]: value });
  };

  const handleSportsChange = (selectedOptions) => {
    const selectedSports = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setNewTournament({ ...newTournament, sports: selectedSports });
  };

  const handleNext = () => {
    setStep(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const token = localStorage.getItem('authToken');
        
        const response = await fetch(`${BACKEND_URL}/submit-tournament`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newTournament)
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error creating tournament:', errorData);
            alert(`Failed to create tournament: ${errorData.error || 'Unknown error occurred'}`);
            return;
        }

        const data = await response.json();
        console.log('Tournament created:', data);

        // Reset form and navigate
        setNewTournament({
            tournamentName: '',
            sports: [],
            startDate: '',
            endDate: '',
            location: '',
            logo: '',
            brochure: '',
            numTeams: 0,
            numPlayers: 0,
            numCisMen: 0,
            numNonCisMen: 0,
            registrationFees: 0,
            verificationCode: '',
            rules: '',
            collegeName: collegeName || '',
            coordinatorEmail: coordinatorEmail || ''
        });
        setStep(1);
        navigate('/dashboard');
    } catch (error) {
        console.error('Error creating tournament:', error);
        alert('An error occurred while creating the tournament. Please try again.');
    }
  };

  return (
    <div className={styles.adminDashboard}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="FanUp Logo" className={styles.logo} />
          <span className={styles.registrationText}>CREATE TOURNAMENT</span>
        </div>
      </nav>
      <CustomNavbar />
      <form className={styles.gameForm} onSubmit={step === 1 ? handleNext : handleSubmit}>
        {step === 1 && (
          <>
            <div className={styles.formGroup}>
              <label htmlFor="tournamentName">Tournament Name:</label>
              <input
                type="text"
                className={styles.adminInput}
                name="tournamentName"
                value={newTournament.tournamentName}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="sports">Select Sport(s):</label>
              <Select
                isMulti
                name="sports"
                options={sportsOptions}
                className={styles.adminInput}
                classNamePrefix="select"
                onChange={handleSportsChange}
                value={sportsOptions.filter(option => newTournament.sports.includes(option.value))}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="startDate">Start Date:</label>
              <input
                type="date"
                className={styles.adminInput}
                name="startDate"
                value={newTournament.startDate}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="endDate">End Date:</label>
              <input
                type="date"
                className={styles.adminInput}
                name="endDate"
                value={newTournament.endDate}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="location">Location:</label>
              <input
                type="text"
                className={styles.adminInput}
                name="location"
                value={newTournament.location}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="logo">Logo (Public Image Link):</label>
              <input
                type="text"
                className={styles.adminInput}
                name="logo"
                value={newTournament.logo}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="brochure">Brochure (Public Image Link):</label>
              <input
                type="text"
                className={styles.adminInput}
                name="brochure"
                value={newTournament.brochure}
                onChange={handleChange}
              />
            </div>
            <button type="button" className={styles.adminButton} onClick={handleNext}>Next</button>
          </>
        )}
        {step === 2 && (
          <>
            <div className={styles.formGroup}>
              <label htmlFor="numTeams">No. of Teams:</label>
              <input
                type="number"
                className={styles.adminInput}
                name="numTeams"
                value={newTournament.numTeams}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="numPlayers">No. of Players:</label>
              <input
                type="number"
                className={styles.adminInput}
                name="numPlayers"
                value={newTournament.numPlayers}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="numCisMen">No. of CIS Men:</label>
              <input
                type="number"
                className={styles.adminInput}
                name="numCisMen"
                value={newTournament.numCisMen}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="numNonCisMen">No. of Non-CIS Men:</label>
              <input
                type="number"
                className={styles.adminInput}
                name="numNonCisMen"
                value={newTournament.numNonCisMen}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="registrationFees">Registration Fees:</label>
              <input
                type="number"
                className={styles.adminInput}
                name="registrationFees"
                value={newTournament.registrationFees}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="verificationCode">Verification Code:</label>
              <input
                type="text"
                className={styles.adminInput}
                name="verificationCode"
                value={newTournament.verificationCode}
                onChange={handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="rules">Rules (Public Image Link):</label>
              <input
                type="text"
                className={styles.adminInput}
                name="rules"
                value={newTournament.rules}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className={styles.adminButton}>Submit Tournament</button>
          </>
        )}
      </form>
    </div>
  );
};

export default CreateTournament;
