import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/fanup_logo_white.png';
import LoadingSpinner from './LoadingSpinner';
import styles from './SignupPage.module.css';
import CustomNavbar from "./CustomNavbar";

function SignupPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    collegeName: '',
    verificationCode: '',
    coordinatorName: '',
    coordinatorNumber: '',
    coordinatorEmail: sessionStorage.getItem('userEmail') || '',
  });
  const [loading, setLoading] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch(`${BACKEND_URL}/verify-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          collegeName: formData.collegeName,
          verificationCode: formData.verificationCode,
        })
      });

      const result = await response.json();

      if (response.ok) {
        if (result.role === 'admin') {
          sessionStorage.setItem('tournamentName', result.tournamentName);
          sessionStorage.setItem('collegeName', formData.collegeName);
          sessionStorage.setItem('userFullName', formData.coordinatorName);
          sessionStorage.setItem('userPhoneNumber', formData.coordinatorNumber);
          navigate('/dashboard', { state: { tournamentName: result.tournamentName } });
        } else if (result.role === 'user') {
          sessionStorage.setItem('collegeName', formData.collegeName);
          sessionStorage.setItem('userFullName', formData.coordinatorName);
          sessionStorage.setItem('userPhoneNumber', formData.coordinatorNumber);
          navigate('/usersignup', { state: { ...formData } });
        }
      } else {
        console.error('Verification failed:', result);
        alert(result.error);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.signupPage}>
      {loading && <LoadingSpinner />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>Registration Page</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.signupText}>Log In</h2>
          <form className={styles.form} onSubmit={handleSubmit}>
            <input
              type="text"
              name="collegeName"
              placeholder="College Name"
              value={formData.collegeName}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <input
              type="text"
              name="verificationCode"
              placeholder="Verification Code"
              value={formData.verificationCode}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <input
              type="text"
              name="coordinatorName"
              placeholder="Full Name"
              value={formData.coordinatorName}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <input
              type="text"
              name="coordinatorNumber"
              placeholder="Phone Number"
              value={formData.coordinatorNumber}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <input
              type="email"
              name="coordinatorEmail"
              placeholder="Email Address"
              value={formData.coordinatorEmail}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
            <button type="submit" className={styles.submitButton}>
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
