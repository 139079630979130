import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import styles from './CustomNavbar.module.css';

function CustomNavbar() {
  return (
    <Navbar expand="lg" className={styles.topNavbar2}>
      <Container fluid> {/* Set Container to fluid */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={styles.navbarCollapse}>
          <Nav className={styles.navItems}>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/">Dashboard</Nav.Link>
            <Nav.Link href="/tournaments">Tournaments</Nav.Link>
            <NavDropdown title="Fantasy" id="basic-nav-dropdown">
              <NavDropdown.Item href="/fantasy">Teams (Coming Soon)</NavDropdown.Item>
              <NavDropdown.Item href="/fantasy/create">Create Team (Coming Soon)</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Stats" id="basic-nav-dropdown">
              <NavDropdown.Item href="/stats">Player Stats (Coming Soon)</NavDropdown.Item>
              <NavDropdown.Item href="/stats/team">Team Stats (Coming Soon)</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
